import React from 'react'
import UploadForm from '../components/UploadForm'

const UploadPage = () => {
  return (
    <center>
      <UploadForm />
    </center>
  )
}

export default UploadPage
